<template>
  <div
    class="min-h-screen flex-1 mt-1 bg-gray-100 px-4 pt-12 shadow-lg items-center"
  >
    <div class="bg-white w-fullrounded-lg shadow">
      <div class="h-20 py-3 flex items-center border-gray-200 p-6 mt-5">
        <div class="flex">
          <button
            :class="$colors.primary"
            class="rounded-full flex justify-center items-center w-8 h-8 mr-3"
            @click="RouterBack()"
          >
            <i class="material-icons">chevron_left</i>
          </button>
          <div v-if="!formData._id" class="text-2xl font-bold text-green-500">
            New Charge
          </div>
          <div v-else class="text-2xl font-bold text-green-500">Edit Charge</div>
        </div>
      </div>

      <div class="">
        <div class="bg-white rounded-lg p-6">
          <div class="bg-white rounded-lg p-6">
                  <div class="grid lg:grid-cols-2 gap-6">
                    <div
                        class="border hover:border-green-500  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
                      >
                        <div
                          class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                        >
                          <p>
                            <label for="country" class="bg-white text-gray-700 px-1"
                              >{{$t('warehouse')}}</label
                            >
                          </p>
                        </div>
                        <p>
                          <v-select
                            @input="setSelected"
                            v-model="formData.warehouse"
                            :options="warehousesusers"
                            label="name"
                          >
                            <template slot="option" slot-scope="option">
                              <img
                                class="flag-img"
                                :src="$f.getCountryCode(option.country)"
                              />
                              {{ option.name }}
                            </template>
                          </v-select>
                        </p>
                      </div>
                      <div v-if="formData.warehouse && formData.warehouse._id"  class="border seller-select h-10 hover:border-green-400 focus-within:border-green-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                        <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                          <p>
                            <label for="seller" class="bg-white text-gray-700 px-1"> Seller
                            <span :class="$colors.required">*</span></label>
                          </p>
                        </div>    
                        <p>
                            <v-select label="fullName" @search="getUsersDebance($event,'ouaka')" v-model="formData.seller" :options="sellers"></v-select>
                        </p>
                        
                      </div>  
                  </div>  
                  <div v-if="formData.warehouse && formData.warehouse._id" class="grid lg:grid-cols-2 gap-6 pt-4">
                      <div
                          class="border hover:border-green-400 w-full focus-within:border-green-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                          <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                              <p>
                                  <label for="customerPhone" class="bg-white text-gray-700 px-1">Comment</label>
                              </p>
                          </div>
                          <p>
                              <input  autocomplete="false" tabindex="0" type="text" v-model="formData.comment" class="py-2 text-xs px-2 outline-none block h-full w-full" />
                          </p>
                      </div>
                      <div class="border hover:border-green-500  hover:border-green-500  focus-within:border-green-500 flex flex-wrap items-stretch  relative h-15 bg-white items-center rounded">
                        <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                          <p>
                            <label for="seller" class="bg-white text-gray-700 px-1"> Charge amount
                            <span :class="$colors.required">*</span></label>
                          </p>
                        </div>    
                        <div class="flex -mr-px justify-center p-1 rounded  shadow ">
                          <span class="flex border border-black items-center leading-normal bg-white px-3 border-0 rounded rounded-r-none text-sm font-bold text-gray-600">
                              <sup class="uppercase" v-if="idWharhouse"> {{idWharhouse.currency}} </sup><span class="select-none ml-2"><img :src="idWharhouse?idWharhouse.flag:''" class="flag-img"></span>
                          </span>
                        </div>
                        <input :disabled="formData.payment" type="number" v-model="formData.amount" min="0" class="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border-0 h-10 border-grey-light rounded text-sm shadow rounded-l-none px-3 self-center relative outline-none">
                      </div>
                  </div>
          </div>     

          <div class="mt-6 pt-3 flex justify-center">
            <button
              class="rounded focus:outline-none outline-none text-gray-100 px-3 w-1/6 py-2 bg-green-500 hover:shadow-inner hover:bg-green-700 transition-all duration-300"
              @click="save"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- ******************* Partie Script *********************** -->
<script>
export default {
  props: {
        currentUser: { type: Object},
        idWharhouse: { type: Object}
  },
  data() {
    return {
      formData: {warehouse:{}},
      sellers:[],
      warehousesusers:[],
      warehouses:[],
      options: this.$countries,
    };
  },
   watch: {
        'idWharhouse': async function(oldVal, newVal) {
           this.idWharhouse=oldVal;  
         //  this.formData.country = this.idWharhouse.country;
          // await this.getSellers();
        }
  },
  async mounted() {
    await this.dataToEdit();
    await this.getWarehouses();
    for (let i in this.warehouses) {
      for (let j in this.currentUser.countries) {
        if(this.warehouses[i].country===this.currentUser.countries[j].code) this.warehousesusers.push(this.warehouses[i]);
      }
    }
   // await this.getSellers();
  },
  methods: {
     RouterBack: function() {
       this.$router.back();
    },
    async getWarehouses(){
        const res = await this.$server.search("warehouses",{limit:10});
        if (res && res.content.results) {
           this.warehouses = res.content.results;
        } else this.warehouses = [];
    }, 
    async setSelected(contry) {
       if(contry){
           if(this.currentUser && this.currentUser.type && this.currentUser.type != "Seller") await this.getSellers(contry.code);
       }
    },
    getUsersDebance: _.debounce(async function(name) {
      if(this.formData.warehouse && this.formData.warehouse.country){
              await this.$store.dispatch('wharhouse/searchUser',{type: "Seller",countries:this.formData.warehouse.country,search:name}).then((res) => {
                this.sellers = res
              })
        }     
     }, 500), 
     async getSellers(country) {
        if(this.formData.warehouse){
              const filter = {
                type: "Seller",
                countries: country,
                limit:10
              };
              const res = await this.$server.search("users", filter);
              if (res && res.content.results) this.sellers = res.content.results;
              else this.sellers = [];
        }
    },
    async save() {
      if (this.formData._id) {

        const data = await this.$server.update("charges", this.formData);
        if(data && data._id) alert("Charge updated", "success");
        else alert(data, "warning");

        }else {
          if(this.checkData()) {
            const data = await this.$server.create("charges", this.formData);
            if (data && data._id) {
              this.formData.seller=null; this.formData.amount=null; this.formData.comment=null;
              alert("Charge created !", "success");
            } else alert(data, "warning");
          } else alert(`you must fill all the fields !! `, "warning");
      }
    },
    checkData() {
      if (this.formData.seller && this.formData.amount && this.formData.warehouse && this.formData.warehouse._id) return true;
      else return false;
    },
    async dataToEdit() {
      if (this.$route.params.id) {
        const editData = await this.$server.get("charges", {id: this.$route.params.id,});
        this.formData = editData.content;
      }
    },
  },
};
</script>

<!-- ******************* Partie style *********************** -->
<style >
[type="color"] {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding: 0;
  width: 15px;
  height: 15px;
  border: none;
}

[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

[type="color"]::-webkit-color-swatch {
  border: none;
}

.color-picker {
  padding: 10px 15px;
  border-radius: 10px;
  border: 1px solid #ccc;
  background-color: #f8f9f9;
}
</style>